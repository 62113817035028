const isWindows = /Windows/.test(navigator.userAgent);

export const getHeightByTemplateName = (data) => {
  const { parent_template_name } = data;

  switch (parent_template_name) {
    // case '1-ICYMI-POLL': case '1-ICYMI-CARTOON':
    //   return isWindows ? '1098px' : '1089px';
    case "1-ICYMI-2AD-1A":
    case "1-COL-ICYMI-2-ADS":
      return isWindows ? "598.8px" : "568.8px";
    case "1-ICYMI-2AD-2A":
      return "750px";
    case "1-ICYMI-POLL-NO-COL3-QR":
    case "1-ICYMI-POLL-NO-COL1-QR":
    case "1-ICYMI-POLL":
    case "1-COL-ICYMI-POLL":
      return  isWindows ? "1120px" : "1100px";
    case "1-ICYMI-CARTOON":
    case "1-COL-ICYMI-CART":
      return isWindows ? "1230px" : "1204px";
    default:
      return "1150px";
  }
};

export const getOriginalHeightByTemplateName = (data) => {
  const { parent_template_name } = data;

  switch (parent_template_name) {
    // case '1-ICYMI-POLL': case '1-ICYMI-CARTOON':
    //   return '1089px';
    case "1-ICYMI-2AD-1A":
    case "1-COL-ICYMI-2-ADS":
      return "569px";
    case "1-ICYMI-2AD-2A":
      return "720px";
    case "1-ICYMI-POLL-NO-COL3-QR":
    case "1-ICYMI-POLL-NO-COL1-QR":
    case "1-ICYMI-POLL":
    case "1-COL-ICYMI-POLL":
      return "1085px";
    case "1-ICYMI-CARTOON":
    case "1-COL-ICYMI-CART":
      return "1204px";
    case "2-ICYMI-CARTOON": case "2-COL-ICYMI-CART":
        return isWindows ? "1559px": "1545px";
    case "2-COL-ICYMI-2-ADS":
      return isWindows ? "1583px" : "1569px";
    default:
      return "1119px";
  }
};

export const getContentHeight = (data) => {
  const { parent_template_name } = data;

  switch (parent_template_name) {
    // case '1-ICYMI-POLL':  case '1-ICYMI-CARTOON':
    //   return 1047;
    case "1-ICYMI-2AD-1A":
    case "1-COL-ICYMI-2-ADS":
      return 539.8;
    case "1-ICYMI-2AD-2A":
      return 691;
    case "1-ICYMI-POLL-NO-COL1-QR":
    case "1-ICYMI-POLL-NO-COL3-QR":
    case "1-COL-ICYMI-POLL":
    case "1-ICYMI-POLL":
      return 1035;
    case "1-ICYMI-CARTOON":
    case "1-COL-ICYMI-CART":
      return 1166.4;
    default:
      return "";
  }
};

export const getContentExpand = (data) => {
  const { parent_template_name } = data;

  switch (parent_template_name) {
    // case "1-ICYMI-POLL": case "1-ICYMI-CARTOON":
    //   return 1047;
    case "1-ICYMI-2AD-1A":
    case "1-COL-ICYMI-2-ADS":
      return 539.8;
    // return 686;
    case "1-ICYMI-POLL-NO-COL1-QR":
    case "1-ICYMI-POLL-NO-COL3-QR":
    case "1-ICYMI-POLL":
    case "1-COL-ICYMI-POLL":
      return 1050;
    case "1-ICYMI-CARTOON":
    case "1-COL-ICYMI-CART":
      return 1166.4;
    default:
      return ""; // or return a default value if needed
  }
};

export const excludedTemplates = [
  // '1-ICYMI-2AD-1A',
  "1-ICYMI-2AD-2A",
  "1-ICYMI-CARTOON-NO-COL1-QR",
  "1-ICYMI-CARTOON-NO-COL3-QR",
];

export const templatesToUsePdfBlob = [
  "1-ICYMI-POLL-NO-COL1-QR",
  "1-ICYMI-POLL-NO-COL3-QR",
  // '1-ICYMI-2AD-1A',
  "1-ICYMI-2AD-2A",
  "1-ICYMI-CARTOON-NO-COL1-QR",
  "1-ICYMI-CARTOON-NO-COL3-QR",
];

export const templates = [
  // '1-ICYMI-POLL-NO-COL1-QR',
  // '1-ICYMI-POLL-NO-COL3-QR',
  // '1-ICYMI-2AD-1A',
  "1-ICYMI-CARTOON-NO-COL1-QR",
  "1-ICYMI-CARTOON-NO-COL3-QR",
];

export const Template_heights = {
  "1-ICYMI-POLL": "1085.3px",
  "1-COL-ICYMI-POLL": "1085.3px",
  "1-ICYMI-CARTOON": "1204px",
  "1-COL-ICYMI-CART": "1204px",
  // '1-ICYMI-2AD-1A': '750px',
  "1-ICYMI-2AD-1A": "569px",
  "1-COL-ICYMI-2-ADS": "569px",
  "1-ICYMI-2AD-2A": "750px",
  "1-ICYMI-POLL-NO-COL3-QR": "1085.3px",
  "1-ICYMI-POLL-NO-COL1-QR": "1085.3px",
};

export const getPdfContainerHeight = (templateName) => {
  switch (templateName) {
    case '2-ICYMI-POLL':
    case '2-COL-ICYMI-POLL':
      return '1162px';
    case '2-ICYMI-CARTOON':
    case '2-COL-ICYMI-CART':
      return '1563px';
    default:
      return '';
  }
};