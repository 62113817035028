const STAGING = "dev"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "8wp55khcx1hqw4oh4tfm55xkljz3ll06wn74qcatlja87fhn",
  dev: "8wp55khcx1hqw4oh4tfm55xkljz3ll06wn74qcatlja87fhn",
  prod: "igapu4iaanwldgbzsinazy7hdlhptwh690usmhrkmly21rwa",
  preview: "lghgym85xf9612idq509udk726gbe3dtvbjpxk3ibxdc0skx",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}